import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import '../../App.css';
import './Attendance.css';
import { Form, Layout, Input, List, Typography, Button, Tag, Modal } from 'antd';

import { useDispatch, useSelector } from "react-redux";
import { setMenu } from '../../redux/R_Action';

import moment from "moment/moment";
import Common from "../../services/Common";

import SettingService from "../../services/callAPI/SettingService"
import AttendanceService from "../../services/callAPI/AttendanceService";

import useScanDetection from "use-scan-detection-react18"; // 바코드 스캐너 라이브러리

const onFinish = (values) => { };
const onFinishFailed = (errorInfo) => { };

const { Content } = Layout;
function Attendance() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo); //eslint-disable-line no-unused-vars
    const r_calc = useSelector(state => state.store.set.calcVisibility); //eslint-disable-line no-unused-vars
    const r_settingInfo = useSelector(state => state.store.set.settingInfo); //eslint-disable-line no-unused-vars

    //const [code, setCode] = useState("");

    const inputRef = useRef(null);
    const [attendanceList, setAttendanceList] = useState([]);

    useScanDetection({
        onComplete: (val) => {
            Call_SetAttendance(val)
        },
        minLength: 8,
    })

    useEffect(() => {
        Check_ExtensionAttendance();

        // 오늘의 근태 정보를 불러온다
        Call_GetAttendanceToday();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const codeHandler = (e) => {
    //     e.preventDefault();
    //     setCode(e.target.value);
    // };

    //#region 근태관리 기능이 켜져있는지 확인하는 함수
    function Check_ExtensionAttendance() {
        SettingService.GetAllSetting(r_token, r_connStr).then((result) => {
            if (result.length > 0) {
                if (result[0].extension_attendance === 0) {
                    Modal.error({
                        title: '오류',
                        content: (
                            <>
                                <p>
                                    근태 관리기능이 사용 중지되어 있습니다!<br />
                                    관리자에게 문의하세요!
                                </p>
                            </>
                        )
                    });

                    dispatch(setMenu('notice'))
                    navigate('/notice')
                } else {
                    dispatch(setMenu('attendance'))
                    navigate('/attendance')
                }
            } else {
                Modal.error({
                    title: '오류',
                    content: (
                        <>
                            <p>설정정보 불러오기 오류!</p>
                        </>
                    )
                });
            }
        });
    }
    //#endregion
    async function Call_GetAttendanceToday() {
        const result = await AttendanceService.GetAttendanceDate(r_token, r_connStr, moment().format('YYYY-MM-DD'));
        setAttendanceList(JSON.parse(JSON.stringify(result)));
    }
    async function Call_SetAttendance(code) {
        console.log(' 22 ' + code);
        const result = await AttendanceService.SetAttendance(r_token, r_connStr, moment().format('YYYY-MM-DD'), code);

        // -3 : 존재하지 않는 코드번호인 경우
        // -2 : 근태정보가 있는데 출근정보가 없는 경우
        // -1 : 두개 이상의 근태정보가 기록된 경우
        // 1 : 근태 정보가 없으면 새롭게 등록
        // 2 : 퇴근 정보가 없으면 퇴근으로 기록
        // 3 : 퇴근 정보가 존재하면 퇴근기록을 변경
        let title = '정보';
        let msg = '';
        switch (result.result.isResult) {
            case 1:
                msg = `${result.result.userName}님 ${result.result.time} 출근시간이 등록되었습니다`;
                break;
            case 2:
                msg = `${result.result.userName}님 ${result.result.time} 퇴근시간이 등록되었습니다`;
                break;
            case 3:
                msg = `${result.result.userName}님 ${result.result.time} 퇴근시간이 변경되었습니다`;
                break;
            case -1:
                title = '오류'
                msg = `${result.result.userName}님 근태정보가 두개이상 기록되어 있습니다!`;
                break;
            case -2:
                title = '오류'
                msg = `${result.result.userName}님 출근정보가 존재하지 않습니다!`;
                break;
            case -3:
                title = '오류'
                msg = `올바르지 못한 사원번호 입니다!`;
                break;
            default:
        }

        //setCode('');
        Call_GetAttendanceToday();
        let modal = null;
        if (title === '정보') {
            modal = Modal.info({
                title: '정보',
                content: (
                    <>
                        <p>
                            {msg}
                        </p>
                    </>
                ),
                okText: '확인',
                maskClosable: true,
                width: '1000px',
                autoFocusButton: 'ok',
            });
        } else {
            modal = Modal.error({
                title: '오류',
                content: (
                    <>
                        <p>
                            {msg}<br />
                            관리자에게 문의하세요!
                        </p>
                    </>
                ),
                okText: '확인',
                maskClosable: true,
                width: '1000px',
                autoFocusButton: 'ok',
            });
        }
        setTimeout(() => {
            Modal.destroyAll();
        }, 1500);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Header />
            <Content
                className="site-layout"
                style={{
                    padding: '20px',
                    height: '93vh',
                    width: Common.ContainerConvert(r_userInfo.system_container_size),
                }}
            >
                <List
                    header={<div>{moment().format('YYYY년 MM월 DD일')} 출퇴근 정보</div>}
                    bordered
                    dataSource={attendanceList}
                    renderItem={(item) => (
                        <List.Item>
                            <Typography.Text mark>&nbsp;[{item.user_name}]&nbsp;</Typography.Text><br />
                            <Tag color="green">출근</Tag>&nbsp;{item.attendance_start_time}<br />
                            <Tag color="red">퇴근</Tag>&nbsp;{item.attendance_end_time}
                        </List.Item>
                    )}
                />
            </Content>
        </div>
    )
}

export default Attendance;