import { HourglassOutlined, CaretRightOutlined, CheckOutlined, PauseOutlined, ExclamationCircleOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment';
const md5 = require('md5');

function ContainerConvert(val) {
  if (val === "container-fluid") {
    return '100%'
  } else if (val === "container-lg") {
    return '70%'
  } else {
    return '100%'
  }
}

function FontConvert(val) {
  if (val !== undefined) {
    //MES와 Font 사이즈 표기가 달라 +2 해줌
    return Number(val) + 2
  } else {
    return Number(14)
  }

}

//키보드 레이아웃
const customLayout = {
  simpleNumbers: [
    "1 2 3 4 5 6 7 8 9 0 {backspace}",
  ],
  default: [
    // "q w e r t y u i o p",
    // "a s d f g h j k l",
    // "{shift} {numbers} {language} z x c v b n m {backspace} {clear}",
    // "{space}"
    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
    "{clear} q w e r t y u i o p [ ] \\",
    ".com a s d f g h j k l ; '",
    "{shift} z x c v b n m , . / {language}",
    "{space}"
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
    "{clear} Q W E R T Y U I O P { } |",
    ".com A S D F G H J K L : \" ",
    "{shift} Z X C V B N M < > ? {language}",
    "{space}"
  ],
  symbols: [
    "[ ] { } # % ^ * + =",
    "_ \\ | ~ < > € £ ¥ ·",
    "{abc} {language} {numbers} . , ? ! ' {backspace} {clear}",
    "{space}"
  ],
  language: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
    "{clear} ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ [ ] \\",
    "{symbols} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ; ' ",
    "{languageShift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ , . / {abc}",
    "{space}"
  ],
  languageShift: [
    "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
    "{clear} ㅃ ㅉ ㄸ ㄲ ㅆ ㅛ ㅕ ㅑ ㅐ ㅔ [ ] \\",
    "{symbols} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ; ' ",
    "{languageShift} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ , . / {abc}",
    "{space}"
  ],
  numbers: [
    "1 2 3 4 5 6 7 8 9 0",
    '- / : ; ( ) $ & @ "',
    "{abc} {language} {symbols} . , ? ! ' {backspace} {clear}",
    "{space}"
  ]
}

//키보드 커스텀
const customDisplay = {
  "{numbers}": "123",
  "{shift}": "⇧",
  "{space}": "space",
  "{backspace}": "⌫",
  "{abc}": "영문",
  "{symbols}": "#+=",
  "{language}": "한글",
  "{languageShift}": "⇧",
  "{clear}": "🗙",
  "{tab}": "⭾",
  "{enter}": "↵ Enter"
};

function StateToText(val) {
  let text;
  switch (val) {
    case 0:
      text = "대기중"
      break;
    case 1:
      text = "일시정지"
      break;
    case 2:
      text = "생산중"
      break;
    case 3:
      text = "외주"
      break;
    case 4:
      text = "생산완료"
      break;
    case 5:
      text = "긴급"
      break;
    default:
      break;
  }

  return text;
}

function StateIcon(state) {
  //HourglassOutlined, CaretRightOutlined, CheckOutlined, PauseOutlined, ExclamationCircleOutlined, ExportOutlined
  let icon;
  let colorClass;
  let modalColorClass;
  switch (state) {
    case '대기중':
      icon = <HourglassOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "wait"
      modalColorClass = "wait-modal"
      break;
    case '일시정지':
      icon = <PauseOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "pause"
      modalColorClass = "pause-modal"
      break;
    case '생산중':
      icon = <CaretRightOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "start"
      modalColorClass = "start-modal"
      break;
    case '외주':
      icon = <ExportOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "outsourcing"
      modalColorClass = "outsourcing-modal"
      break;
    case '생산완료':
      icon = <CheckOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "end"
      modalColorClass = "end-modal"
      break;
    case '긴급':
      icon = <ExclamationCircleOutlined style={{ verticalAlign: '2px' }} />
      colorClass = "emergency"
      modalColorClass = "emergency-modal"
      break;
    default:
      break;
  }

  const ret = {
    icon: icon,
    colorClass: colorClass,
    modalColorClass: modalColorClass
  }

  return ret;
}

function tableSorter(a, b, type) {
  let sorter;
  switch (type) {
    case 'number':
      sorter = a - b;
      break;
    case 'string':
      sorter = (a || '').localeCompare((b || ''));
      break;
    case 'date':
      sorter = moment(a).unix() - moment(b).unix();
      break;
  }

  return sorter;
}

export default { ContainerConvert, FontConvert, customLayout, customDisplay, StateIcon, StateToText, tableSorter };