import { Button, Form, Layout, Radio } from 'antd';
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './Login.css';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setConnStr, setUserInfo, setDbInfo, setMenu, setSettingInfo } from '../../redux/R_Action';
import UserService from '../../services/callAPI/UserService';
import SettingService from '../../services/callAPI/SettingService';

const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

{/* 사업장 선택 화면 */ }
function Company() {

    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);

    //로그인 화면에서 받아온 정보
    const props = useLocation();

    const navigate = useNavigate();
    const [dbList, setDbList] = useState([]);
    const [token, setToken] = useState();
    const [db, setDb] = useState();
    const [user, setUser] = useState();
    // const [connStr, setConnStr] = useState();

    useEffect(() => {
        console.log(props.state);
        setDbList(props.state.dbList)
        setToken(props.state.accessToken);
        setUser(props.state.user)
    }, [])

    const onChange = (e) => {
        const val = e.target.value;

        setDb(val);
    };

    async function Call_SelectDB() {
        let result = await UserService.SelectDB(db.db_info_connectionString, r_token)
        if (result.isConn == true) {
            dispatch(setAccessToken(token))
            dispatch(setConnStr(db.db_info_connectionString))
            dispatch(setUserInfo(user))
            dispatch(setDbInfo(db))
            dispatch(setMenu())
            navigate('/main');
        } else {
            alert("DB 정보가 올바르지 않습니다. 서버 관리자에게 문의해주세요");
            return;
        }
    }
    function Call_LoginPage() {
        localStorage.removeItem("auto_login_id");
        localStorage.removeItem("auto_login_password");
        navigate('/');
    }

    return (
        <Layout className="layout">

            <div className='App-body'>
                <img className="login-image" alt="a02.png" src="a01.png" /><br />
                <h2>회사 선택</h2><br />
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        className="Align-Center"
                        wrapperCol={{ sm: 24 }}
                    >
                        <Radio.Group onChange={onChange} value={db}>
                            {dbList.map((obj) => {
                                return (
                                    <div key={obj.db_info_idx}>
                                        <Radio id={obj.db_info_idx} value={obj} >
                                            <label className="btn btn-light bg-white border w-100 form-check-label" htmlFor={obj.db_info_idx}>
                                                업체명 : {obj.db_info_companyName}<br />
                                                사업자등록번호 : {obj.db_info_businessNumber}
                                            </label>
                                        </Radio>
                                    </div>
                                )
                            })}
                        </Radio.Group>

                    </Form.Item>


                    <Form.Item
                        name="alert"
                        wrapperCol={{
                            // offset: 8,
                            // span: 16,
                        }}
                    >
                        <div className="alert alert-primary" style={{ textAlign: 'center' }} role="alert">
                            계정에 연결된 회사가 여러개 입니다.<br />
                            관리할 회사를 선택하세요
                        </div>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ sm: 24 }}
                    >
                        <Button className="button" style={{ width: "100%" }} type="primary" htmlType="submit" onClick={() => { Call_SelectDB() }}>
                            선택
                        </Button>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ sm: 24 }}
                    >
                        <Button className="button" style={{ width: "100%" }} color='ffffff' htmlType="submit" onClick={() => Call_LoginPage()}>
                            로그인 화면으로
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            {/* <Footer></Footer> */}
        </Layout>
    )
}

export default Company;