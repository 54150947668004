import Header from "../../components/Header";
import '../../App.css';
import { Layout, Table, Modal, Button, Input, } from 'antd';
import React, { useState, useEffect, useRef } from "react";
import { useTableSearch } from "../../services/useTableSearch"
import { useSelector, useDispatch } from "react-redux";
import API from "../../services/API"
import moment from "moment";
import Common from "../../services/Common";
import Keyboard from 'react-simple-keyboard'
import "react-simple-keyboard/build/css/index.css";
import hangul from "hangul-js";
import NoticeService from "../../services/callAPI/NoticeService";
import Calculator from "../../components/Calculator";

let tempToken;
let tempConnStr;

//Filtering
const { Search } = Input;
const fetchUsers = async () => {
  const noticeList = await NoticeService.GetAllNotice(tempToken, tempConnStr);
  const val = { data: noticeList }

  return val;
};

const { Content } = Layout;
const { Column, ColumnGroup } = Table;

function AllNotice() {
  const dispatch = useDispatch();
  const r_token = useSelector(state => state.store.set.accessToken);
  const r_connStr = useSelector(state => state.store.set.connStr);
  const r_userInfo = useSelector(state => state.store.set.userInfo);
  const r_dbInfo = useSelector(state => state.store.set.dbInfo);
  const r_calc = useSelector(state => state.store.set.calcVisibility);

  tempToken = r_token; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)
  tempConnStr = r_connStr; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)

  const [noticeList, setNoticeList] = useState([]);
  const [latestNotice, setLatestNotice] = useState({
    notice_idx: '',
    user_email: '',
    user_name: '',
    notice_subject: '',
    notice_content: '',
    notice_write_date: '',
    notice_enable: '',
  });

  const columns = [
    {
      title: 'index',
      dataIndex: 'notice_idx',
      key: 'notice_idx',
      hidden: true
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (item, record, index) => (<>{index + 1}</>),
    },
    {
      title: '제목',
      dataIndex: 'notice_subject',
      key: 'notice_subject',
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            // console.log(record, rowIndex);
          },
        };
      },
      render: (text, record) => <Button className='button' onClick={() => showModal(record)}>{text}</Button>,
      sorter: (a, b) => Common.tableSorter(a.notice_subject, b.notice_subject, 'string')
    },
    {
      title: '작성자',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: (a, b) => Common.tableSorter(a.user_name, b.user_name, 'string')
    },
    {
      title: '작성일',
      dataIndex: 'notice_write_date_',
      key: 'notice_write_date_',
      // render: (text) => moment(text).format('YYYY년 MM월 DD일')
      sorter: (a, b) => Common.tableSorter(a.notice_write_date, b.notice_write_date, 'date')
    },

  ].filter(item => !item.hidden);


  //Filter
  const [searchVal, setSearchVal] = useState(null);
  const { filteredData, loading } = useTableSearch({
    searchVal,
    retrieve: fetchUsers
  });

  //Modal
  const [modaldata, setmodaldata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (record) => {
    console.log(record);

    setmodaldata(record);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Keyboard
  const [layoutName, setLayoutName] = useState("language");
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const keyboard = useRef();

  useEffect(() => {
    // console.log("rendering~")
    Call_GetAllNotice();

    function clickHanlder(e) {
      // console.log(e.target.nodeName)
      // console.log(e.target.classList)
      if (
        !(e.target.nodeName === "INPUT") &&
        !e.target.classList.contains("hg-button")
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, [])

  async function Call_GetAllNotice() {
    const result = await NoticeService.GetAllNotice(r_token, r_connStr);
    if (result.length > 0) {

      let tempDate = new Date(0, 1, 1, 0, 0, 0, 0);
      let tempNotice;

      //가장 최근 공지사항 선별
      result.forEach(element => {
        if (tempDate <= new Date(element.notice_write_date)) {
          tempNotice = element;
        }
      });

      setNoticeList(result);
      setLatestNotice(tempNotice);

      return result;

    } else {
      return null;
    }
  }

  //Keyboard onChange Handler 
  const keyboardOnchange = (input) => {
    const keyAssy = hangul.assemble(input)
    setSearchVal(keyAssy)
  }

  //Keyboard onKeyPress Handler 
  const keyboardOnKeyPress = (button) => {
    // console.log(button)
    if (
      button === `{numbers}` ||
      button === `{abc}` ||
      button === `{shift}` ||
      button === `{symbols}` ||
      button === `{language}` ||
      button === `{languageShift}` ||
      button === `{clear}`
    ) {
      handleShift(button);
    }
  }

  const handleShift = (button) => {
    switch (button) {
      case "{shift}":
        setLayoutName(layoutName === "default" ? "shift" : "default");
        break;
      case "{numbers}":
        setLayoutName("numbers");
        break;
      case "{symbols}":
        setLayoutName("symbols");
        break;
      case "{abc}":
        setLayoutName("default");
        break;
      case "{language}":
        setLayoutName("language");
        break;
      case "{languageShift}":
        setLayoutName(layoutName === "language" ? "languageShift" : "language");
        break;
      case "{clear}":
        setSearchVal("");
        keyboard.current.clearInput();
      default:
        break;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />

      <Content
        className="site-layout"
        style={{
          padding: '20px',
          height: '93vh',
          background: "#ffffff",
          width: Common.ContainerConvert(r_userInfo.system_container_size),
        }}
      >
        <div style={{ height: "5vh", display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
          <h2>전체 공지사항</h2>
        </div>


        <div style={{ display: 'flex', justifyContent: 'right', }}>
          <Search
            onChange={e => setSearchVal(e.target.value)}
            placeholder="검색"
            enterButton
            style={{ position: "sticky", top: "0", left: "0", paddingBottom: '15px', width: '30vw' }}
            onFocus={() => { setKeyboardVisibility(true) }}
            value={searchVal}
          />

        </div>
        <Table
          rowKey="notice_idx"
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          locale={{
            triggerDesc: '내림차순 정렬',
            triggerAsc: '오름차순 정렬',
            cancelSort: '정렬 취소',
          }}
        />
        <Modal
          centered={true}
          width={'50vw'}
          title="공지사항"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={'확인'}
          cancelText={'닫기'}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <div
            style={{
              padding: 24,
              // minHeight: "50vh",
              // minWidth: "50vw",
              // background: colorBgContainer,
              background: "#FFF2CC",
            }}
          >
            <div style={{ padding: "50px" }}><h2 style={{ textAlign: "center" }}>【 {modaldata.notice_subject} 】</h2></div>
            <div style={{ padding: "10px" }}><h5 style={{ textAlign: "right", fontWeight: "bold" }}>{moment(modaldata.notice_write_date).format('YYYY년 MM월 DD일 HH:mm:ss')} 등록</h5></div>
            <div style={{ paddingTop: "100px" }}><h4 style={{ textAlign: "center", whiteSpace: 'pre-wrap' }}>{modaldata.notice_content}</h4></div>
          </div>
        </Modal>

      </Content>
      {
        keyboardVisibility &&
        // <div style={{ position: 'absolute ', bottom: '0', width:Common.ContainerConvert(r_userInfo.system_container_size), padding:'20px'}}>
        <div style={{ position: 'absolute ', bottom: '0', width: '60%', padding: '20px', }}>
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            onChange={keyboardOnchange}
            onKeyPress={keyboardOnKeyPress}
            layoutName={layoutName}
            layout={Common.customLayout}
            display={Common.customDisplay}
          />
        </div>
      }
      {
        r_calc &&
        <div style={{ position: 'absolute ', right: '0', top: '10vh', width: '30%', padding: '20px', opacity: '0.7' }}>
          <Calculator />
        </div>
      }
      {/* <Footer></Footer> */}
    </div>
  )
}

export default AllNotice;