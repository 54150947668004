import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function PrivateRoute(){
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);

    let isLogin;
    if (((typeof r_token != "undefined") && (typeof r_token.valueOf() == "string")) && (r_token.length > 0)){
        isLogin = true;
    }else{
        isLogin = false;
    }

    if(!isLogin){
        alert("로그인 후 시도해주세요");
        return <Navigate to="/" />;
    }else{
        return <Outlet/>
    }
}

export default PrivateRoute;