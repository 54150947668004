import { Button, Checkbox, Form, Input, Layout, Modal } from 'antd';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';
import axios from 'axios';
import Keyboard from 'react-simple-keyboard'
import "react-simple-keyboard/build/css/index.css";
import hangul from "hangul-js";
import Common from "../../services/Common";

import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setConnStr, setDbInfo, setMenu, setUserInfo } from '../../redux/R_Action';
import UserService from '../../services/callAPI/UserService';
import Password from 'antd/es/input/Password';

import CryptoJS from 'crypto-js';

import { PING_APP_EXIT } from '../../ipc'

const onFinish = (values) => {
    //console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    //console.log('Failed:', errorInfo);
};

var isLoggedIn = false;

function Login() {
    var ipcRenderer = null;
    try { ipcRenderer = window.require('electron').ipcRenderer; } catch { ipcRenderer = null; }

    const Ping_AppExit = () => {
        if (ipcRenderer != null) {
            ipcRenderer.send(PING_APP_EXIT, 'send');
        } else {
            Modal.error({
                title: '오류',
                content: (
                    <>
                        <p>해당 기능은 웹버전에서 사용이 불가능합니다!</p>
                    </>
                )
            });
        }
    }

    const secretKey = "weffice-pop";

    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);

    const navigate = useNavigate();

    const [id, SetId] = useState("");
    const [pw, SetPw] = useState("");
    const [autoLogin, SetAutoLogin] = useState(true);
    const [autoLoginFlag, SetAutoLoginFlag] = useState(false);

    //Keyboard
    const [layoutName, setLayoutName] = useState("");
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);
    const keyboard = useRef();
    const [focusedInput, setFocusedInput] = useState("");

    useLayoutEffect(() => {
        if (id && pw && autoLoginFlag == true) {
            Call_Login();
        }
    })

    useEffect(() => {
        //1. 클릭 이벤트 추가 - Keyboard Visible
        function clickHanlder(e) {
            if (
                !(e.target.nodeName === "INPUT") &&
                !e.target.classList.contains("hg-button")
            ) {
                setKeyboardVisibility(false);
            }
        }
        window.addEventListener("click", clickHanlder);

        // 2. 로컬스토리지에 자동로그인 정보가 있는지 확인
        var auto_login_id = localStorage.getItem("auto_login_id");
        var auto_login_password = localStorage.getItem("auto_login_password");

        if (auto_login_id && auto_login_password) {
            // 자동로그인 정보가 있으면 곧바로 로그인 시도
            SetId(decrypt(auto_login_id));
            SetPw(decrypt(auto_login_password));
            SetAutoLoginFlag(true);
        }
    }, [])
    useEffect(() => {
        if (keyboard.current != undefined) {
            keyboard.current.clearInput();
        }
    }, [focusedInput])

    const idHandler = (e) => {
        e.preventDefault();
        SetId(e.target.value);
    };
    const pwHandler = (e) => {
        e.preventDefault();
        SetPw(e.target.value);
    };
    const autoLoginHandler = ({ target }) => {
        SetAutoLogin(!autoLogin);
    };

    //Keyboard onChange Handler - 검색어 수정
    const keyboardOnchange2 = (input) => {
        const keyAssy = hangul.assemble(input)

        if (focusedInput == "id") {
            SetId(keyAssy)
        } else if (focusedInput == "pw") {
            SetPw(input)
        }
    }

    //Keyboard onKeyPress Handler - 검색어 수정
    const keyboardOnKeyPress2 = (button) => {
        if (
            button === `{numbers}` ||
            button === `{abc}` ||
            button === `{shift}` ||
            button === `{symbols}` ||
            button === `{language}` ||
            button === `{languageShift}` ||
            button === `{clear}`
        ) {
            handleShift(button);
        }
    }

    const handleShift = (button) => {
        switch (button) {
            case "{shift}":
                setLayoutName(layoutName === "default" ? "shift" : "default");
                break;
            case "{numbers}":
                setLayoutName("numbers");
                break;
            case "{symbols}":
                setLayoutName("symbols");
                break;
            case "{abc}":
                setLayoutName("default");
                break;
            case "{language}":
                setLayoutName("language");
                break;
            case "{languageShift}":
                setLayoutName(layoutName === "language" ? "languageShift" : "language");
                break;
            case "{clear}":
                if (focusedInput == "id") {
                    SetId("");
                } else if (focusedInput == "pw") {
                    SetPw("");
                }
                keyboard.current.clearInput();
                break;
            default:
                break;
        }
    };

    function encrypt(val) {
        return CryptoJS.AES.encrypt(val, secretKey).toString();
    }
    function decrypt(val) {
        const bytes = CryptoJS.AES.decrypt(val, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    async function Call_Login() {
        const result = await UserService.Login(id, pw, r_token);
        if (result.isSuccess == true) {
            // jhseo; 자동로그인 체크 되어있는지 확인
            if (autoLogin == true) {
                // 자동로그인에 체크되어 있으면
                // 로컬 스토리지에 아이디, 비밀번호 암호화하여 저장
                localStorage.setItem("auto_login_id", encrypt(id));
                localStorage.setItem("auto_login_password", encrypt(pw));
            } else {
                // 자동로그인에 체크되어 있지 않으면
                // 로컬 스토리지의 아이디, 비밀번호 폐기
                localStorage.removeItem("auto_login_id");
                localStorage.removeItem("auto_login_password");
            }

            //관리 사업장 2개 이상이면 선택 페이지
            if (result.dbList.length > 1) {
                navigate("/company", { state: result });
            }
            //관리 사업장 1개이면 메인 페이지
            else {
                dispatch(setAccessToken(result.accessToken))
                dispatch(setConnStr(result.dbList[0].db_info_connectionString))
                dispatch(setUserInfo(result.user))
                dispatch(setDbInfo(result.dbList[0]))
                dispatch(setMenu())
                navigate("/main", { state: result });
            }

        } else {
            Modal.error({
                title: '로그인 실패',
                content: (
                    <>
                        <p>아이디 또는 비밀번호가 올바르지 않습니다!</p>
                    </>
                )
            });
        }
    }

    return (
        <Layout className="layout">
            <div className='App-body'>
                <img className="login-image" alt="a02.png" src="a01.png" /><br />
                <h2>로그인</h2><br />
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        className="Align-Center"
                        // label="이메일"
                        wrapperCol={{ sm: 24 }}
                        rules={[
                            {
                                required: true,
                                message: '이메일을 입력해주세요!',
                            },
                        ]}
                    >
                        <Input onChange={idHandler} value={id} size="large" placeholder="이메일" onFocus={(e) => { setKeyboardVisibility(true); setFocusedInput("id"); }} />
                    </Form.Item>

                    <Form.Item
                        // label="비밀번호"
                        wrapperCol={{ sm: 24 }}
                        rules={[
                            {
                                required: true,
                                message: '비밀번호를 입력해주세요!',
                            },
                        ]}
                    >
                        <Input.Password onChange={pwHandler} value={pw} size="large" placeholder="비밀번호" onFocus={(e) => { setKeyboardVisibility(true); setFocusedInput("pw"); }} />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            // offset: 8,
                            // span: 16,
                        }}
                    >
                        <Checkbox checked={autoLogin} onChange={autoLoginHandler}>자동로그인</Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{ sm: 24 }}
                    >
                        <Button className="button" style={{ width: "100%" }} type="primary" htmlType="submit" onClick={() => { Call_Login(); }}>
                            로그인
                        </Button><br /><br />
                        <Button type="primary" danger style={{ width: "100%" }} onClick={() => { Ping_AppExit(); }}>종료</Button>
                    </Form.Item>
                </Form>
            </div>

            {
                keyboardVisibility &&
                // <div style={{ position: 'absolute ', bottom: '0', width:Common.ContainerConvert(r_userInfo.system_container_size), padding:'20px',}}>
                <div style={{ position: 'absolute ', bottom: '0', width: '44%', padding: '20px', }}>
                    <Keyboard
                        keyboardRef={(r) => (keyboard.current = r)}
                        onChange={keyboardOnchange2}
                        onKeyPress={keyboardOnKeyPress2}
                        layoutName={layoutName}
                        layout={Common.customLayout}
                        display={Common.customDisplay}
                        buttonTheme={[{ class: "hot-key", buttons: "{enter} .com {clear} {backspace} {space} {shift} {languageShift}" }]}
                    />
                </div>
            }
            {/* <Footer></Footer> */}
        </Layout>
    )
}

export default Login;