import axios from "axios";

function RefreshToken(token){
    // const { token } = useToken();
    // const { changeToken } = useToken();
    
    const config = {
        data: {},
        headers:{
            'access-token': token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }

    return axios.get('api/token/access-token', config)
    .then((response)=>{
        
        const newToken = response.data.accessToken;
        if(newToken != null){
            // changeToken(token);
            return newToken;
        }else{
            return null;
        }
    })
    .catch((err) => {
        return null;
    });

}

export {RefreshToken};