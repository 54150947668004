import Header from "../../components/Header";
import '../../App.css';

import { Layout, Select, Button } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import Common from "../../services/Common";
import API from "../../services/API"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { set, setAccessToken, setConnStr, setUserInfo, setDbInfo, setMenu } from "../../redux/R_Action";
import UserService from "../../services/callAPI/UserService";
import Calculator from "../../components/Calculator";

const { Content } = Layout;

function Setting() {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const r_token = useSelector(state => state.store.set.accessToken);
  const r_connStr = useSelector(state => state.store.set.connStr);
  const r_userInfo = useSelector(state => state.store.set.userInfo);
  const r_dbInfo = useSelector(state => state.store.set.dbInfo);
  const r_calc = useSelector(state => state.store.set.calcVisibility);

  const [user, setUser] = useState({
    user_idx: r_userInfo.user_idx,
    user_email: r_userInfo.user_email,
    user_password: r_userInfo.user_password,
    user_name: r_userInfo.user_name,
    system_font_size: r_userInfo.system_font_size,
    system_pop_font_size: r_userInfo.system_pop_font_size,
    system_container_size: r_userInfo.system_container_size,
  })

  const fontHandler = (value) => {
    console.log(`selected ${value}`);

    const copiedData = JSON.parse(JSON.stringify(user));
    copiedData.system_pop_font_size = value;
    setUser(copiedData)
  };

  const containerHandler = (value) => {
    console.log(`selected ${value}`);

    const copiedData = JSON.parse(JSON.stringify(user));
    copiedData.system_container_size = value;
    setUser(copiedData)
  };

  function Logout() {
    localStorage.removeItem("auto_login_id");
    localStorage.removeItem("auto_login_password");
    dispatch(setAccessToken(''))
    dispatch(setConnStr(''))
    dispatch(setUserInfo(''))
    dispatch(setDbInfo(''))
    dispatch(setMenu(''))
    navigate('/')
  }

  async function Call_UpdateUser(idx, contents) {
    const result = await UserService.UpdateUser(r_token, r_connStr, idx, contents);
    if (result.affectedRows > 0) {
      dispatch(setUserInfo(contents))
      alert("시스템 설정이 저장되었습니다.")
      return result;
    } else {
      alert("저장 실패..")
      return null;
    }
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      {/* <div className="App-body">
        <h1>현재 공지사항</h1>
        
      </div> */}

      <Content
        className="site-layout"
        style={{
          padding: '20px',
          height: '93vh',
          background: "#ffffff",
          width: Common.ContainerConvert(r_userInfo.system_container_size),
        }}
      >
        <div style={{ height: "5vh", display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
          <h2>환경설정</h2>
        </div>

        <div style={{ padding: "10px" }}>
          <Select
            defaultValue={r_userInfo.system_pop_font_size !== undefined ? r_userInfo.system_pop_font_size : 14}
            style={{ width: 400 }}
            onChange={fontHandler}
            options={[
              { value: '7', label: '7' },
              { value: '8', label: '8' },
              { value: '9', label: '9' },
              { value: '10', label: '10' },
              { value: '11', label: '11' },
              { value: '12', label: '12' },
              { value: '13', label: '13' },
              { value: '14', label: '14' },
              { value: '15', label: '15' },
              { value: '16', label: '16' },
              { value: '17', label: '17' },
            ]}
          />
        </div>
        <div style={{ padding: "10px" }}>
          <Select
            defaultValue={r_userInfo.system_container_size !== undefined ? r_userInfo.system_container_size : "container-fluid"}
            style={{ width: 400 }}
            onChange={containerHandler}
            options={[
              { value: 'container-fluid', label: '꽉차게' },
              { value: 'container-lg', label: '여유롭게' },
            ]}
          />
        </div>
        <div style={{ paddingTop: "100px" }}>
          <Button className='button' onClick={() => { Call_UpdateUser(user.user_idx, user) }}>저장</Button>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <Button className="button" type='primary' danger style={{ marginLeft: 'auto', display: 'inline-flex', alignItems: 'center' }} onClick={() => Logout()}>로그아웃</Button>
        </div>

      </Content>
      {
        r_calc &&
        <div style={{ position: 'absolute ', right: '0', top: '10vh', width: '30%', padding: '20px', opacity: '0.7' }}>
          <Calculator />
        </div>
      }

      {/* <Footer></Footer> */}
    </div>
  )
}

export default Setting;