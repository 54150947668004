import API from '../API';
import axios from 'axios';

//#region 로그인
function Login(id, pw, accessToken) {
    const data = {
        email: id,
        password: pw
    }

    const config = {
        headers: {
            'access-token': accessToken,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true // Cookie 함께 전송하는 옵션
    }

    return axios.post('api/login', data, config).then((response) => {
        const result = response.data;
        return result;
    }).catch(function (error) {
        console.log(error);
    })
}
//#endregion

//#region DB 선택
function SelectDB(connStr, accessToken) {
    const data = {
        connStr: connStr
    }

    const config = {
        headers: {
            'access-token': accessToken,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true // Cookie 함께 전송하는 옵션
    }

    return axios.post('api/login/company', data, config).then((response) => {
        const result = response.data;
        return result;
    })
        .catch(function (error) {
            console.log(error);
        })
}
//#endregion

function UpdateUser(accessToken, connStr, idx, contents) {
    let config = {
        data: {
            'user_idx': contents.user_idx,
            'user_email': contents.user_email,
            'user_password': contents.user_password,
            'user_name': contents.user_name,
            'system_font_size': contents.system_font_size,
            'system_pop_font_size': contents.system_pop_font_size,
            'system_container_size': contents.system_container_size,
        },
        headers: {
            'access-token': accessToken,
            'connection-string': connStr,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }

    return API.put(`api/user/${idx}`, config.data, config).then(async (response) => {
        const result = response.data.result;
        return result;
    })
        .catch(function (error) {
            console.log(error);
        })

}

function TestAPI(accessToken) {

    let config = {
        data: {},
        headers: {
            'access-token': accessToken,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }

    return API.get('api/user/test', config).then((response) => {
        return response;
    })
        .catch(function (error) {
            console.log(error);
        })
}

const UserServices = {
    Login,
    SelectDB,
    UpdateUser,
    TestAPI
}

export default UserServices;