import Header from "../../components/Header";
import '../../App.css';
import { Breadcrumb, Layout, Menu, theme, Select, Space, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import Common from "../../services/Common";
import NoticeService from "../../services/callAPI/NoticeService";
import Calculator from "../../components/Calculator";

const {  Content } = Layout;
function Notice(){
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);
    const r_calc = useSelector(state => state.store.set.calcVisibility);

    const [noticeList, setNoticeList] = useState([]);
    const [latestNotice, setLatestNotice] = useState({
      notice_idx: '',
      user_email: '',
      user_name: '',
      notice_subject: '',
      notice_content: '',
      notice_write_date: '',
      notice_enable: '',
    });

    const {
      token: { colorBgContainer },
    } = theme.useToken();

    const handleChange = (value) => {
      // console.log(`selected ${value}`);
      const notice = noticeList.find(x=>x.notice_idx == value);
      setLatestNotice(notice)
    };

    useEffect(()=>{
      // console.log("rendering~")
      Call_GetAllNotice();
    }, [])

    
    async function Call_GetAllNotice(){
      const result = await NoticeService.GetAllNotice(r_token, r_connStr);
      if(result.length > 0){
        let tempDate = new Date(0, 1, 1, 0, 0, 0, 0);
        let tempNotice;

        //가장 최근 공지사항 선별
        result.forEach(element => {
          if(tempDate <= new Date(element.notice_write_date)){
            tempNotice = element;
          }
        });

        setNoticeList(result);
        setLatestNotice(tempNotice);

      }else{

      }
    }


    return(
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Header />
          {/* <div className="App-body">
            <h1>현재 공지사항</h1>
            
          </div> */}

          <Content
              className="site-layout"
              style={{
                padding: '20px',
                height: '93vh',
                width: Common.ContainerConvert(r_userInfo.system_container_size),
              }}
            >
              <div style={{height:"5vh", display:"flex", justifyContent: "right", paddingBottom: "10px"}}>
                <Select
                  // defaultValue="【 금일 건강검진 일정 】"
                  value={latestNotice.notice_idx}
                  style={{ width: 400}}
                  onChange={handleChange}
                  // options={[
                  //   { value: '【 금일 건강검진 일정 】', label: '【 금일 건강검진 일정 】' },
                  //   { value: '【 매주 금요일 5:30 청소 】', label: '【 매주 금요일 5:30 청소 】' },
                  //   { value: '【 안전 교육 】', label: '【 안전 교육 】' },
                  //   { value: 'disabled', label: 'Disabled', disabled: true },
                  // ]}
                  options={noticeList.map((obj)=>{
                    return {value: obj.notice_idx, label: obj.notice_subject}
                  })}

                />
              </div>
              
              <div
                style={{
                  // padding: 24,
                  minHeight: "85vh",
                  // background: colorBgContainer,
                  background: "#FFF2CC",
                }}
              >
                <div style={{padding:"50px"}}><h1 style={{textAlign:"center"}}>【 {latestNotice.notice_subject} 】</h1></div>
                <div style={{padding:"10px"}}><h5 style={{textAlign:"right", fontWeight:"bold"}}>{moment(latestNotice.notice_write_date).format('YYYY년 MM월 DD일 HH:mm:ss')} 등록</h5></div>
                <div style={{paddingTop:"100px"}}><h2 style={{textAlign:"center", whiteSpace:'pre-wrap'}}>{latestNotice.notice_content}</h2></div>
                
                
              </div>
            </Content>
            {
              r_calc && 
              <div style={{ position: 'absolute ', right: '0', top:'10vh', width:'30%', padding:'20px', opacity:'0.7'}}>
                <Calculator />
              </div>
            }
          {/* <Footer></Footer> */}
        </div>
    )
}

export default Notice;