import persistReducer from 'redux-persist/es/persistReducer';
import { STATE } from './R_Action'
import { INCREMENT, DECREMENT, SET, SET_ACCESS_TOKEN, SET_CONN_STR, SET_ONLINE, SET_USER_INFO, SET_DB_INFO, SET_SETTING_INFO, SET_MENU, SET_CALC } from './R_Action';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// config 작성
const persistConfig = {
    key: "root", // localStorage key 
    storage, // localStorage
    whitelist: ["set"], // target (reducer name)
}

function set(state = STATE, action) {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.value
            }
        case SET_CONN_STR:
            return {
                ...state,
                connStr: action.value
            }
        case SET_ONLINE:
            return {
                ...state,
                online: action.value
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.value
            }
        case SET_DB_INFO:
            return {
                ...state,
                dbInfo: action.value
            }
        case SET_SETTING_INFO:
            return {
                ...state,
                settingInfo: action.value
            }
        case SET_MENU:
            return {
                ...state,
                selectedMenu: action.value
            }
        case SET_CALC:
            return {
                ...state,
                calcVisibility: action.value
            }
        ////
        case SET:
            return {
                ...state,
                value: action.value
            }
        case INCREMENT:
            return {
                ...state,
                value: state.value + 1
            }
        case DECREMENT:
            return {
                ...state,
                value: state.value - 1
            }
        default:
            return state
    }
}

const reducer = combineReducers({ set });

export default persistReducer(persistConfig, reducer)