import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Main from './pages/Main/Main.js';
import Login from './pages/Login/Login';
import Company from './pages/Login/Company';
import Notice from './pages/Notice/Notice.js';
import AllNotice from './pages/Notice/AllNotice.js';
import Manufacture from './pages/Manufacture/Manufacture.js';
import Stock from './pages/Stock/Stock';
import Setting from './pages/Setting/Setting';
import Attendance from './pages/Attendance/Attendance';
import PrivateRoute from './pages/PrivateRoute';
import { Interceptor } from './services/API'
import { ConfigProvider, Empty } from 'antd';
import { useEffect } from 'react';
import { stringify } from 'qs';
import Common from './services/Common';
import Calculator from './components/Calculator';

function App() {
  const dispatch = useDispatch();
  const r_token = useSelector(state => state.store.set.accessToken);
  const r_connStr = useSelector(state => state.store.set.connStr);
  const r_userInfo = useSelector(state => state.store.set.userInfo);
  const r_dbInfo = useSelector(state => state.store.set.dbInfo);

  useEffect(() => {
  }, [r_userInfo])

  return (
    <ConfigProvider
      theme={{
        //전체 폰트 설정
        token: {
          fontFamily: 'Pretendard-Medium',
          fontSize: r_userInfo.system_pop_font_size ? Common.FontConvert(r_userInfo.system_pop_font_size) : 14
        },
        //Ant Design 컴포넌트 전체 스타일 적용
        components: {
          Layout: {
            bodyBg: '#ffffff',
            // headerBg:"#ffffff"
          }
        }
      }}

    >
      <BrowserRouter>
        <Interceptor>

          <Routes>

            <Route exact path='/' element={<div><Login /></div>}></Route>
            <Route path='/company' element={<div><Company /></div>}></Route>

            {/* 로그인 해야 접근 가능한 페이지 */}
            <Route element={<PrivateRoute />}>
              <Route path='/main' element={<div>{/*<Main/>*/}<Notice /></div>}></Route>
              <Route path='/notice' element={<div><Notice /></div>}></Route>
              <Route path='/allNotice' element={<div><AllNotice /></div>}></Route>
              <Route path='/attendance' element={<div><Attendance /></div>}></Route>
              <Route path='/manufacture' element={<div><Manufacture /></div>}></Route>
              <Route path='/stock' element={<div><Stock /></div>}></Route>
              <Route path='/setting' element={<div><Setting /></div>}></Route>
              {/* <Route path='/calculator' element={<div><Calculator/></div>}></Route> */}
            </Route>

            {/* 빈 페이지 */}
            <Route path='*' element={<Empty />}></Route>
          </Routes>

        </Interceptor>
      </BrowserRouter>

    </ConfigProvider>
  );
}

export default App;
