import React, { useEffect, useState } from "react";
import "./Calculator.css";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setCalc } from "../redux/R_Action";

function Calculator(){
    const [formula, setFormula] = useState('');
    const [num, setNum] = useState(0);
    const [oldnum, setOldNum] = useState(0);
    const [operator, setOperator] = useState();
    const [oldOperator, setOldOperator] = useState();
    const [tempResult, setTempResult] = useState(0);
    const [prevButton, setPrevButton] = useState();
    const [fontSize, setFontSize] = useState(40);

    //redux
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);
    const r_calc = useSelector(state => state.store.set.calcVisibility);

    function storePrevButton(e){
        var input = e.target.value;
        setPrevButton(input)
    }

    function inputNum(e) {
        let result;
        var input = e.target.value;
        
        if (num === 0) {
            result = input;
            // setNum(input);
        } else {
            result = num + input;
            // setNum(num + input);
        }
        
        //길이 검사
        if(result.toString().length > 16) return;

        setNum(result)
    }

    function clear() {
        setNum(0);
        setFormula('');
        setOperator();
        setOldOperator();
    }
    function porcentage() {
        setNum(num / 100);
    }

    function changeSign() {
        if (num > 0) {
            setNum(-num);
        } else {
            setNum(Math.abs(num));
        }
    }

    //계산기호 표기 변환
    function operatorNotation(operator){
        let op = "";
        switch(operator){
            case "+":
                op = "+";
                break;
            case "-":
                op = "-";
                break;
            case "*":
                op = "x";
                break;
            case "/":
                op = "÷";
                break;
        }
        return op;
    }

    //결과값 표기 변환
    function resultNotation(result){
        let val = '';
        if(result.toString().includes('.')){
            var iVal = result.toString().split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            var dVal = result.toString().split('.')[1];
            val = `${iVal}.${dVal}`
        }else{
            val = result.toString().split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return val;
    }
    
    //Operator Click
    function operatorHandler(e) {
        var operatorInput = e.target.value;
        let op = operatorNotation(operatorInput);

        //기존 OP가 없으면
        if(operator === undefined){
            setFormula(`${num} ${op}`)  // 계산식 저장
            setOperator(operatorInput); // 계산기호 저장
            setOldNum(num);             // oldNum 저장
            setNum(0);                  // num 초기화
        }
        //기존 OP가 있으면
        else{
            //직전 눌린 버튼이 OP 이면
            if(prevButton === "/" || prevButton === "*" || prevButton === "+" || prevButton === "-" ){
                setFormula(`${oldnum} ${op}`)
                setOperator(operatorInput);

                return;
            }

            //계산 이어가기
            var temp = calculate();

            setFormula(`${temp} ${op}`)
            setOperator(operatorInput);
            setOldNum(temp);
            setNum(0);
        }
        //
    }

    function calculate() {
        let op = operatorNotation(operator);
        let result = 0;

        if (operator === "/") {
            result = parseFloat(oldnum) / parseFloat(num);
        } else if (operator === "*") {
            result = parseFloat(oldnum) * parseFloat(num);
        } else if (operator === "-") {
            // console.log(oldnum)
            // console.log(num)
            // console.log(oldnum-num)
            result = parseFloat(oldnum) - parseFloat(num);
        } else if (operator === "+") {
            result = parseFloat(oldnum) + parseFloat(num);
        }

        if(op){
            setFormula(`${parseFloat(oldnum)} ${op} ${parseFloat(num)} =`)
            setOperator()
            setNum(result)
        }else{
            setFormula(`${parseFloat(num)} =`)
            setOperator()
            setNum(num)
        }

        return result;
    }

    //폰트 변경
    useEffect(()=>{
        var len = num.toString().length;
        //총 자리수는 16제한
        if(len > 12 && len <= 16){
            setFontSize(32)
        }
        else if(len >= 16){
            setFontSize(24)
        }
        else if(len < 13){
            setFontSize(40)
        }else{
            setFontSize(40)
        }
    }, [num])

    function CloseCalc(){
        dispatch(setCalc(false));
    }

    return(
        <div>
            <Box m={5} />
            <Container maxWidth="xs">
                <div className='wrapper' onClick={storePrevButton}>
                    <div style={{display:'flex', justifyContent:'end', alignItems:'center' }}><Button danger type='primary' size='large' shape='circle'onClick={CloseCalc}>Ｘ</Button></div>
                    <Box m={12} />
                    <h5 className='formula'>{formula ? formula : "\u00A0"}</h5>
                    <h1 className='result' style={{fontSize:fontSize, height:'50px', display:'flex', justifyContent:'end', alignItems:'center' }}>{resultNotation(num)/*num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')*/}</h1>
                    <button className='calcButton' onClick={clear} value="C">C</button>
                    <button className='calcButton' onClick={changeSign} value="+/-">+/-</button>
                    <button className='calcButton' onClick={porcentage} value="%">%</button>
                    <button className='calcButton orange' onClick={operatorHandler} value="/">
                        ÷
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={7}>
                        7
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={8}>
                        8
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={9}>
                        9
                    </button>
                    <button className='calcButton orange' onClick={operatorHandler} value="*">
                        x
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={4}>
                        4
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={5}>
                        5
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={6}>
                        6
                    </button>
                    <button className='calcButton orange' onClick={operatorHandler} value="-">
                        -
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={1}>
                        1
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={2}>
                        2
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={3}>
                        3
                    </button>
                    <button className='calcButton orange' onClick={operatorHandler} value="+">
                        +
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={0}>
                        0
                    </button>
                    <button className='calcButton gray' onClick={inputNum} value={"."}>
                        .
                    </button>
                    <button className='calcButton gray' style={{ visibility: "hidden" }}>
                        ,
                    </button>
                    <button className='calcButton orange' onClick={calculate} value="=">
                        =
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default Calculator;