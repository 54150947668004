import API from '../API';
import axios from 'axios';
import moment from 'moment';
import Common from '../Common';

function GetAllWork(accessToken, connStr){

    if(!navigator.onLine){
        return [];
    }

    const config = {
        data: {},
        headers:{
            'access-token': accessToken,
            'connection-string' : connStr,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }

    return API.get('api/work/works', config).then(async (result)=>{ 
        let validList = [];
        const ret = result.data.result;

        ret.forEach((element)=>{
            //미종료, 공개 인 작업지시만 가져오기
            if(element.ended == "0" && element.public == "0"){
                
                //표기 변환 (날짜, 상태)
                element.order_date_ = moment(element.order_date).format('YYYY년 MM월 DD일');
                element.state_ = Common.StateToText(element.state);

                //필터링 된 List
                validList.push(element)

            }else{
                return;
            }
        })

        //내림차순 정렬
        validList.sort((a,b) => {
            if(a.state > b.state) return -1;
            if(a.state < b.state) return 1;
            return 0;
        })


        return validList;
    })
    .catch(function(err){
        console.log(err);
        return [];
    })
}

function UpdateWork(accessToken, connStr, idx, contents){
    let config = {
      data: {
        'product_idx' : contents.product_idx,
        'product_name' : contents.product_name,
        'product_standard' : contents.product_standard,
        'work_no' : contents.work_no,
        'order_date' : contents.order_date,
        'order_quantity' : contents.order_quantity,
        'good_quantity' : contents.good_quantity,
        'state' : contents.state,
        'public' : contents.public,
        'ended' : contents.ended,
        'description' : contents.description,
        'work_idx' : contents.work_idx,
        'warehouse_idx' : contents.warehouse_idx,
      },
      headers:{
        'access-token': accessToken,
        'connection-string' : connStr,
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      withCredentials: true
    }

    return API.put(`api/work/${idx}`, config.data, config).then(async (response) => {
      const result = response.data.result;
      return result
    })
    .catch(function(error){
      console.log(error);
    })
  }

export default {GetAllWork, UpdateWork};