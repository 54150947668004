import axios from 'axios';
import * as TokenManager from "./TokenManager.js";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { set, setAccessToken, setConnStr, setOnline, setUserInfo, setDbInfo } from '../redux/R_Action.js';

//Hook 사용하기 위한 구조 (instance, Interceptor)

const instance = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? '' : 'https://api.eastflag.co.kr'
    // baseURL: process.env.NODE_ENV === 'production' ? '' : ''
})


const Interceptor = ({ children }) => {
    //토큰
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const navigate = useNavigate();

    const reqInterceptor = instance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            // 요청 에러 직전 호출됩니다.
            return Promise.reject(error);
        }
    );

    const resInterceptor = instance.interceptors.response.use(

        (response) => {
            /*
                http status가 200인 경우
                응답 성공 직전 호출됩니다.
                .then() 으로 이어집니다.
            */
            return response;
        },
        async (error) => {
            try {
                /*
                    http status가 200이 아닌 경우
                    응답 에러 직전 호출됩니다.
                    .catch() 으로 이어집니다.
                */

                console.log("Interceptor ...")

                const { response, config } = error;

                //응답 받은 상태 코드가 401 이라면
                if (response.status === 401) {
                    dispatch(setOnline(true));
                    const val = await TokenManager.RefreshToken(null).then(async (newToken) => {

                        if (newToken != null) {
                            //토큰 재발급 성공
                            console.log("토큰 재발급 성공 : ", newToken)

                            //새 Access Token 헤더에 담기
                            config.headers['access-token'] = newToken;

                            //Redux 에 새 토큰 담기
                            dispatch(setAccessToken(newToken))

                            //재요청
                            return await axios(config);
                        } else {
                            //토큰 재발급 실패 - 로그아웃 처리
                            console.log("토큰 재발급 실패")
                            alert('세션이 만료되었습니다. 재로그인 해주세요')
                            dispatch(setAccessToken(''))
                            dispatch(setConnStr(''))
                            dispatch(setUserInfo(''))
                            dispatch(setDbInfo(''))
                            navigate('/')

                            // return Promise.reject(error);
                            return false;
                        }
                    })

                    return val;
                }
            } catch (error) {
                dispatch(setOnline(false));
                return Promise.reject(error);
            }
        }
    );

    return children;
}




// //1. 요청 인터셉터 : 2개의 콜백 함수를 받습니다.
// instance.interceptors.request.use(
//     (config) => {
//         return config;
//     },
//     (error) => {
//     // 요청 에러 직전 호출됩니다.
//     return Promise.reject(error);
//     }
// );

// //2. 응답 인터셉터 : 2개의 콜백 함수를 받습니다.
// instance.interceptors.response.use(

//     (response) => {
//     /*
//         http status가 200인 경우
//         응답 성공 직전 호출됩니다.
//         .then() 으로 이어집니다.
//     */
//     return response;
//     },
//     async (error) => {
//         /*
//             http status가 200이 아닌 경우
//             응답 에러 직전 호출됩니다.
//             .catch() 으로 이어집니다.
//         */

//         console.log("인터셉터 타나요~~~~")

//         const {response, config} = error;

//         //응답 받은 상태 코드가 401 이라면
//         if(response.status === 401) {
//             const val = await TokenManager.RefreshToken(null).then(async (newToken) => {

//                 if(newToken != null) {
//                     //토큰 재발급 성공
//                     console.log("토큰 재발급 성공 : ", newToken)

//                     //새 Access Token 헤더에 담기
//                     config.headers['access-token'] = newToken;
//                     // changeToken(newToken)

//                     //재요청
//                     return await axios(config); 
//                 }else{
//                     //토큰 재발급 실패 - 로그아웃 처리
//                     console.log("토큰 재발급 실패")
//                     return null
//                 }
//             })

//             return val;
//             //새 Access Token 저장
//             // changeToken(data);

//             // //새 Access Token 헤더에 담기
//             // config.headers['access-token'] = val;

//             // //재요청
//             // return await axios(config); 
//         }

//         return Promise.reject(error);
//     }
// );


export default instance;
export { Interceptor };