import Header from "../../components/Header.js"
import API from "../../services/API.js"

import './Main.css';

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Common from "../../services/Common";
import UserService from "../../services/callAPI/UserService.js";
import Calculator from "../../components/Calculator.js";

const { Content } = Layout;

function Main() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const r_token = useSelector(state => state.store.set.accessToken);
  const r_connStr = useSelector(state => state.store.set.connStr);
  const r_userInfo = useSelector(state => state.store.set.userInfo);
  const r_dbInfo = useSelector(state => state.store.set.dbInfo);
  const r_calc = useSelector(state => state.store.set.calcVisibility);

  async function TestAPI() {
    const result = await UserService.TestAPI(r_token);
    console.log(result)
    alert("실행~")
  }

  return (
    <div>
      <Layout className="layout" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Header></Header>
        <Content
          className="site-layout"
          style={{
            padding: '20px',
            height: '93vh',
            background: "#ffffff",
            width: Common.ContainerConvert(r_userInfo.system_container_size),
          }}
        >
          <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
            <h1>메인페이지</h1>
            <Button className="button" onClick={() => { TestAPI() }}>TEST API 요청</Button>
            <br /><Button className="button" onClick={() => alert("토큰 : " + r_token + "\n\nDB 연결 : " + r_connStr)}>토큰값 확인</Button>
            <br />
            <Button
              className="button"
              onClick={() => alert("USER INFO : " + r_userInfo.user_email + "\n\nDB INFO : " + r_dbInfo.db_info_companyName + "\n\nFont Size : " + r_userInfo.system_pop_font_size + "\n\Container Size : " + r_userInfo.system_container_size)}>
              유저/DB정보 확인
            </Button>
          </div>

        </Content>
        {
          r_calc &&
          <div style={{ position: 'absolute ', right: '0', top: '10vh', width: '30%', padding: '20px', opacity: '0.7' }}>
            <Calculator />
          </div>
        }

      </Layout>
    </div>
  )

}

export default Main