import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Layout, Menu, theme, ConfigProvider, Button, Modal, Typography } from 'antd';
import { Calendar } from "antd";
import { } from '@ant-design/icons';

import { useDispatch, useSelector } from "react-redux";
import { setMenu, setCalc, setSettingInfo } from '../redux/R_Action';
import SettingService from "../services/callAPI/SettingService";

import { PING_APP_EXIT, PING_WIN_EXIT } from '../ipc'
import packageJson from '../../package.json';

const { Text } = Typography;

function Header() {
    var ipcRenderer = null;
    try { ipcRenderer = window.require('electron').ipcRenderer; } catch { ipcRenderer = null; }

    const items = [
        {
            label: '공지',
            key: 'notice',
            children: [
                {
                    type: 'group',
                    children: [
                        {
                            label: '현재 공지사항',
                            key: 'preNotice',
                            target: '/notice'
                        },
                        {
                            label: '전체 공지사항',
                            key: 'allNotice',
                            target: '/allNotice'
                        },
                    ],
                },
            ],
        },
        {
            label: '출/퇴근',
            key: 'attendance',
            target: '/attendance'
        },
        {
            label: '생산',
            key: 'manufacture',
            target: '/manufacture',
        },
        // {
        //     label: '재고현황',
        //     key: 'stock',
        //     target: '/stock'
        // },
        {
            label: '환경설정',
            key: 'setting',
            target: '/setting'
        },
    ];

    const [isModal, setIsModal] = useState(false);
    const handleModalCancel = (e) => {
        setIsModal(false)
    }

    const onProgramExit = (() => {
        // 프로그램 종료
        ipcRenderer.send(PING_APP_EXIT, 'send');
    });
    const onSystemExit = (() => {
        // 시스템 종료
        ipcRenderer.send(PING_WIN_EXIT, 'send');
    })

    const Ping_Exit = () => {
        if (ipcRenderer != null) {
            setIsModal(true)
        } else {
            Modal.error({
                title: '오류',
                content: (
                    <>
                        <p>해당 기능은 웹버전에서 사용이 불가능합니다!</p>
                    </>
                )
            });
        }
    }

    const { Header } = Layout;
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const [current, setCurrent] = useState('');
    //const [settingInfo, setSettingInfo] = useState([]);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_online = useSelector(state => state.store.set.online);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);
    const r_selectedMenu = useSelector(state => state.store.set.selectedMenu);
    const r_calc = useSelector(state => state.store.set.calcVisibility)

    const r_settingInfo = useSelector(state => state.store.set.settingInfo);

    const onClick = (e) => {
        switch (e.key) {
            case "notice":
                dispatch(setMenu('notice'))
                navigate('/notice')
                break;
            case "preNotice":
                dispatch(setMenu('preNotice'))
                navigate('/notice')
                break;
            case "allNotice":
                dispatch(setMenu('allNotice'))
                navigate('/allNotice')
                break;
            case "attendance":
                dispatch(setMenu('attendance'))
                navigate('/attendance')
                break;
            case "manufacture":
                dispatch(setMenu('manufacture'))
                navigate('/manufacture')
                break;
            case "stock":
                dispatch(setMenu('stock'))
                navigate('/stock')
                break;
            case "setting":
                dispatch(setMenu('setting'))
                navigate('/setting')
                break;
        }
    };

    useEffect(() => {
    }, []);

    function OpenCalc() {
        dispatch(setCalc(true));
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemPaddingInline: '50px',
                    },
                },
            }}
        >
            <Header style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: "20px", height: '7vh', width: '100%' }}>
                <div className="demo-logo" />
                <Link to='/main' className="pd-side" onClick={() => { dispatch(setMenu('')) }}><img className="header-image" alt="a02.png" src="a02.png" /></Link>
                <Menu style={{ flex: "auto" }} onClick={onClick} theme="dark" selectedKeys={[r_selectedMenu]} mode="horizontal" items={items} />

                <Text keyboard>v{packageJson.version}</Text>&nbsp;&nbsp;
                <Button className="button" style={{ marginLeft: 'auto', display: 'inline-flex', alignItems: 'center', height: "5vh" }} onClick={OpenCalc}>계산기</Button>&nbsp;&nbsp;
                <Button type="primary" danger onClick={Ping_Exit} style={{ height: "5vh" }}>종료</Button>
            </Header>
            <Modal
                open={isModal}
                title="확인"
                //onOk={handleOk}
                onCancel={handleModalCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={onProgramExit} danger style={{ height: "5vh", backgroundColor: "#eca52b", color: "#ffffff" }}>
                        프로그램 종료
                    </Button>,
                    <Button key="submit" type="primary" onClick={onSystemExit} danger style={{ height: "5vh" }}>
                        시스템 종료
                    </Button>,
                    <Button key="back" onClick={handleModalCancel} style={{ height: "5vh" }}>
                        취소
                    </Button>
                ]}
            >
                <p>종료하시겠습니까?</p>
            </Modal>
        </ConfigProvider>

    )
}

export default Header;