import API from '../API';
import moment from 'moment';

function GetAllSetting(accessToken, connStr) {
  let config = {
    data: {},
    headers: {
      'access-token': accessToken,
      'connection-string': connStr,
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    withCredentials: true
  }

  return API.get('api/setting/settings', config).then((response) => {
    const result = response.data.result;
    return result;
  })
    .catch(function (error) {
      console.log(error);
    })

}

export default { GetAllSetting };