import { useState, useEffect } from "react";

export const useTableSearch = ({ searchVal, retrieve }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [searchIndex, setSearchIndex] = useState([]);
  const [loading, setLoading] = useState(true);

  //외부에서 조회 데이터 갱신을 위한 Trigger
  const [trigger, setTrigger] = useState(true);

  useEffect(() => {
    //최초 trigger = true 이므로 1회 실행. 
    //'생산' Polling 시 trigger true -> false 과정에서 2번 호출방지
    if(trigger == false) return;

    // '생산' Polling 시 로딩창 없애기 위해 setLoading 미실행 - useTableSearch 호출시 기본값이 true 이기 때문에 타 페이지에서도 최초 1회는 로딩 실행
    // setLoading(true);
    
    const crawl = (user, allValues) => {
      if (!allValues) allValues = [];
      for (var key in user) {
        if (typeof user[key] === "object") crawl(user[key], allValues);
        else allValues.push(user[key] + " ");
      }
      return allValues;
    };
    const fetchData = async () => {
      const { data: users } = await retrieve();
      setOrigData(users);
      setFilteredData(users);
      const searchInd = users.map(user => {
        const allValues = crawl(user);
        return { allValues: allValues.toString() };
      });
      setSearchIndex(searchInd);

      if (users) {setLoading(false); setTrigger(false)}
    };
    fetchData();
  }, [trigger]);


  // useEffect(() => {
  //   setLoading(true);
  //   const crawl = (user, allValues) => {
  //     if (!allValues) allValues = [];
  //     for (var key in user) {
  //       if (typeof user[key] === "object") crawl(user[key], allValues);
  //       else allValues.push(user[key] + " ");
  //     }
  //     return allValues;
  //   };
  //   const fetchData = async () => {
  //     const { data: users } = await retrieve();
  //     setOrigData(users);
  //     setFilteredData(users);
  //     const searchInd = users.map(user => {
  //       const allValues = crawl(user);
  //       return { allValues: allValues.toString() };
  //     });
  //     setSearchIndex(searchInd);
  //     if (users) setLoading(false);
  //   };
  //   fetchData();
  // }, [retrieve]);

  useEffect(() => {
    if (searchVal) {
      const reqData = searchIndex.map((user, index) => {
        if (user.allValues.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0)
          return origData[index];
        return null;
      });
      setFilteredData(
        reqData.filter(user => {
          if (user) return true;
          return false;
        })
      );
    } else setFilteredData(origData);
  }, [searchVal, origData, searchIndex]);

  return { filteredData, loading, setTrigger };
};
