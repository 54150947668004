import { json } from "react-router-dom";

export const STATE = {
    value: '',

    accessToken: '',
    connStr: '',

    online: true,

    userInfo: {
        user_idx: '',
        user_email: '',
        user_password: '',
        user_name: '',
        system_font_size: '',
        system_pop_font_size: '',
        system_container_size: '',
    },

    settingInfo: {
        idx: '',
        company_name: '',
        owner_name: '',
        business_number: '',
        company_address: '',
        business_conditions: '',
        business_sectors: '',
        company_phone: '',
        company_fax: '',
        company_email: '',
        extension_attendance: ''
    },

    dbInfo: {
        db_info_idx: '',
        db_info_connectionString: '',
        db_info_companyName: '',
        db_info_businessNumber: '',
        db_info_licenseStartDate: '',
        db_info_licenseEndDate: '',
        db_info_version: '',
    },

    selectedMenu: '',

    calcVisibility: false,
};

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_CONN_STR = 'SET_CONN_STR'
export const SET_ONLINE = 'SET_ONLINE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_DB_INFO = 'SET_DB_INFO'
export const SET_MENU = 'SET_MENU'
export const SET_CALC = 'SET_CALC'
export const SET_SETTING_INFO = 'SET_SETTING_INFO'

export const SET = 'SET'
export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'

export function setAccessToken(token) {
    return {
        type: SET_ACCESS_TOKEN,
        value: token
    };
}

export function setConnStr(connStr) {
    return {
        type: SET_CONN_STR,
        value: connStr
    };
}

export function setOnline(isOnline) {
    return {
        type: SET_ONLINE,
        value: isOnline
    }
}

export function setUserInfo(userInfo) {
    const copiedObj = JSON.parse(JSON.stringify(userInfo));

    return {
        type: SET_USER_INFO,
        value: copiedObj
    };
}

export function setDbInfo(dbInfo) {
    const copiedObj = JSON.parse(JSON.stringify(dbInfo));

    return {
        type: SET_DB_INFO,
        value: copiedObj
    };
}

export function setSettingInfo(settingInfo) {
    const copiedObj = JSON.parse(JSON.stringify(settingInfo));

    return {
        type: SET_SETTING_INFO,
        value: copiedObj
    };
}

export function setMenu(menu) {
    return {
        type: SET_MENU,
        value: menu
    };
}

export function setCalc(visible) {
    return {
        type: SET_CALC,
        value: visible
    };
}

export function set(token) {
    return {
        type: SET,
        value: token
    };
}

export function increment() {
    return {
        type: INCREMENT
    };
}

export function decrement() {
    return {
        type: DECREMENT
    };
}