import API from '../API';
import moment from 'moment';

function GetAllNotice(accessToken, connStr){
    let config = {
      data: {},
      headers:{
        'access-token': accessToken,
        'connection-string' : connStr,
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      withCredentials: true
    }

    return API.get('api/notice/notices', config).then((response) => {
      const result = response.data.result;

      //날짜 표기 변환
      result.forEach((element)=>{
        element.notice_write_date_ = moment(element.notice_write_date).format('YYYY년 MM월 DD일');
      })

      return result;
    })
    .catch(function(error){
      console.log(error);
    })

  }

  export default {GetAllNotice}