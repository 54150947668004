import Header from "../../components/Header";
import '../../App.css';
import { useTableSearch } from "../../services/useTableSearch";
import React, { useEffect, useRef, useState } from "react";
import { Layout, Table, Input } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import Common from "../../services/Common";
import hangul from "hangul-js";
import Keyboard from 'react-simple-keyboard'
import StockService from "../../services/callAPI/StockService";
import Calculator from "../../components/Calculator";

const { Content } = Layout;
const { Column, ColumnGroup } = Table;

let tempToken;
let tempConnStr;

//Filtering
const { Search } = Input;
const fetchUsers = async () => {

  const stockList = await StockService.GetAllStock(tempToken, tempConnStr);
  const val = { data: stockList }

  return val;
};


function Stock() {

  //redux
  const dispatch = useDispatch();
  const r_token = useSelector(state => state.store.set.accessToken);
  const r_connStr = useSelector(state => state.store.set.connStr);
  const r_userInfo = useSelector(state => state.store.set.userInfo);
  const r_dbInfo = useSelector(state => state.store.set.dbInfo);
  const r_calc = useSelector(state => state.store.set.calcVisibility);

  tempToken = r_token; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)
  tempConnStr = r_connStr; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)

  //Filter
  const [searchVal, setSearchVal] = useState(null);
  const { filteredData, loading, setTrigger } = useTableSearch({
    searchVal,
    retrieve: fetchUsers
  });

  //Keyboard
  const [layoutName, setLayoutName] = useState("language");
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const keyboard = useRef();

  //Calculator
  const [calcVisibility, setCalcVisibility] = useState(true);

  useEffect(() => {
    function clickHanlder(e) {
      //클릭 이벤트 추가 - Keyboard Visible
      if (
        !(e.target.nodeName === "INPUT") &&
        !e.target.classList.contains("hg-button")
      ) {
        setKeyboardVisibility(false);
      }
    }

    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, [])

  //Column (재고 테이블)
  const columns = [
    {
      title: 'index',
      dataIndex: 'stock_idx',
      key: 'stock_idx',
      hidden: true
    },
    {
      title: 'productIndex',
      dataIndex: 'product_idx',
      key: 'product_idx',
      hidden: true
    },
    {
      title: '품목',
      dataIndex: 'product_name',
      key: 'product_name',
      width: "25%",
      sorter: (a, b) => Common.tableSorter(a.product_name, b.product_name, 'string')

      // onCell: (record, rowIndex) => {

      //   return {
      //       onClick: (ev) => {
      //           console.log(record, rowIndex);
      //       },
      //   };
      // },
      // render: (text) => <Button onClick={()=>showModal()}>{text}</Button>,
    },
    {
      title: '규격',
      dataIndex: 'product_standard',
      key: 'product_standard',
      width: "25%",
      sorter: (a, b) => Common.tableSorter(a.product_standard, b.product_standard, 'string')
    },
    {
      title: '재고량',
      dataIndex: 'stock_quantity',
      key: 'stock_quantity',
      width: "10%",
      sorter: (a, b) => Common.tableSorter(a.stock_quantity, b.stock_quantity, 'number')
    },
    {
      title: '단위',
      dataIndex: 'unit',
      key: 'unit',
      width: "10%",
      sorter: (a, b) => Common.tableSorter(a.unit, b.unit, 'string')
    },
    {
      title: '비고',
      dataIndex: 'description',
      key: 'description',
      width: "30%",
      sorter: (a, b) => Common.tableSorter(a.description, b.description, 'string')
    },

  ].filter(item => !item.hidden);

  //Keyboard onChange Handler 
  const keyboardOnchange = (input) => {
    const keyAssy = hangul.assemble(input)
    setSearchVal(keyAssy)
  }

  //Keyboard onKeyPress Handler 
  const keyboardOnKeyPress = (button) => {
    // console.log(button)
    if (
      button === `{numbers}` ||
      button === `{abc}` ||
      button === `{shift}` ||
      button === `{symbols}` ||
      button === `{language}` ||
      button === `{languageShift}` ||
      button === `{clear}`
    ) {
      handleShift(button);
    }
  }

  const handleShift = (button) => {
    switch (button) {
      case "{shift}":
        setLayoutName(layoutName === "default" ? "shift" : "default");
        break;
      case "{numbers}":
        setLayoutName("numbers");
        break;
      case "{symbols}":
        setLayoutName("symbols");
        break;
      case "{abc}":
        setLayoutName("default");
        break;
      case "{language}":
        setLayoutName("language");
        break;
      case "{languageShift}":
        setLayoutName(layoutName === "language" ? "languageShift" : "language");
        break;
      case "{clear}":
        setSearchVal("");
        keyboard.current.clearInput();
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />

      <Content
        className="site-layout"
        style={{
          padding: '20px',
          height: '93vh',
          background: "#ffffff",
          width: Common.ContainerConvert(r_userInfo.system_container_size),
        }}
      >
        <div style={{ height: "5vh", display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
          <h2>재고현황</h2>
        </div>


        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Search
            onChange={e => setSearchVal(e.target.value)}
            placeholder="검색"
            enterButton
            style={{ position: "sticky", top: "0", left: "0", paddingBottom: '15px', width: '30vw' }}
            onFocus={() => { setKeyboardVisibility(true) }}
            value={searchVal}
          />
        </div>

        <Table
          rowKey="stock_idx"
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          locale={{
            triggerDesc: '내림차순 정렬',
            triggerAsc: '오름차순 정렬',
            cancelSort: '정렬 취소',
          }}
        />

      </Content>
      {
        keyboardVisibility &&

        <div style={{ position: 'absolute ', bottom: '0', width: '60%', padding: '20px', }}>
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            onChange={keyboardOnchange}
            onKeyPress={keyboardOnKeyPress}
            layoutName={layoutName}
            layout={Common.customLayout}
            display={Common.customDisplay}
          />
        </div>
      }
      {
        r_calc &&
        <div style={{ position: 'absolute ', right: '0', top: '10vh', width: '30%', padding: '20px', opacity: '0.7' }}>
          <Calculator />
        </div>
      }
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Stock;