import Header from "../../components/Header";
import '../../App.css';
import { useTableSearch } from "../../services/useTableSearch"
import React, { useEffect, useRef, useState } from "react";
import { Layout, Table, Modal, Button, Input, Checkbox, Form } from 'antd';
import API from "../../services/API"
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { HourglassOutlined, CaretRightOutlined, CheckOutlined, PauseOutlined, ExclamationCircleOutlined, ExportOutlined } from '@ant-design/icons';
import styles from './Manufacture.css';
import Common from "../../services/Common";
import Keyboard from 'react-simple-keyboard'
import "react-simple-keyboard/build/css/index.css";
import hangul from "hangul-js";
import WorkService from "../../services/callAPI/WorkService";
import Calculator from "../../components/Calculator";

const { Content } = Layout;
const { Column, ColumnGroup } = Table;

let tempToken;
let tempConnStr;

//Filtering
const { Search } = Input;
const fetchUsers = async () => {
    const workList = await WorkService.GetAllWork(tempToken, tempConnStr);
    const val = { data: workList }

    return val;
};

function Manufacture() {
    //redux
    const dispatch = useDispatch();
    const r_token = useSelector(state => state.store.set.accessToken);
    const r_connStr = useSelector(state => state.store.set.connStr);
    const r_userInfo = useSelector(state => state.store.set.userInfo);
    const r_dbInfo = useSelector(state => state.store.set.dbInfo);
    const r_calc = useSelector(state => state.store.set.calcVisibility);

    //생산량 변수
    const [form] = Form.useForm();
    const [goodQuantity, setGoodQuantity] = useState();
    const [checked, setChecked] = useState(true);
    const inputQunatity = useRef(null);

    tempToken = r_token; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)
    tempConnStr = r_connStr; //TopLevel에서의 Axios Call 위한 초기화 (검색 용도)

    //Filter
    const [searchVal, setSearchVal] = useState(null);
    const { filteredData, loading, setTrigger } = useTableSearch({
        searchVal,
        retrieve: fetchUsers
    });

    //Keyboard
    const [layoutName, setLayoutName] = useState("language");
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);
    const keyboard = useRef();
    const keyboard_search = useRef();

    useEffect(() => {
        //1. 클릭 이벤트 추가 - Keyboard Visible
        function clickHanlder(e) {
            if (
                !(e.target.nodeName === "INPUT") &&
                !e.target.classList.contains("hg-button")
            ) {
                setKeyboardVisibility(false);
            }
        }
        window.addEventListener("click", clickHanlder);

        //2. 생산목록 Polling 시작
        let polling = setInterval(() => {
            setTrigger(true);
        }, 5000);

        //페이지 벗어날 때, 클릭이벤트 ,Polling 종료
        return () => {
            window.removeEventListener("click", clickHanlder, true);
            clearInterval(polling);
        }
    }, [])

    //Confirm State Modal (상태변경 -> 확인Modal)
    const [modal, contextHolder] = Modal.useModal();
    const confirmState = (val) => {
        modal.confirm({
            title: '알림',
            icon: <ExclamationCircleOutlined />,
            content: "상태를 '" + Common.StateToText(val) + "' (으)로 변경하시겠습니까?",
            okText: '확인',
            cancelText: '취소',
            centered: true,
            onOk: async () => {
                const ret = await onStateConfirm(val);
                if (ret === true) {
                    setIsModalOpen(false)
                } else {
                    alert("변경 실패...")
                }
            },
        });
    };

    //Confirm State Modal OK Click (상태변경 -> 확인버튼 클릭 이벤트)
    const onStateConfirm = async (val) => {
        const copiedData = JSON.parse(JSON.stringify(modaldata));
        copiedData.state = val;

        const result = await Call_UpdateWork(copiedData.idx, copiedData);

        if (result.affectedRows > 0) {
            return true;
        } else {
            return false;
        }
    }

    //State Modal (상태변경Modal)
    const [modaldata, setmodaldata] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (record) => {
        console.log(record);

        setmodaldata(record);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //GoodQuantity Modal (생산량변경Modal)
    const [modaldata2, setmodaldata2] = useState([]);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const showModal2 = (record) => {
        console.log(record);
        setmodaldata2(record);
        setIsModalOpen2(true);
    };
    const handleOk2 = async (goodQuantity, isChecked) => {

        if (!goodQuantity || goodQuantity < 0) {
            console.log(goodQuantity)
            form.validateFields(['formQuantity'])
            return;
        }

        if (goodQuantity >= 2147483648) {
            setGoodQuantity(0);
            keyboard.current.setInput(0)
            form.setFields([{
                name: 'formQuantity',
                errors: ['2,147,483,647개 이하로 입력해주세요'],
            }])
            return
        }

        const copiedData = JSON.parse(JSON.stringify(modaldata2));
        console.log(modaldata2)

        if (isChecked) {
            copiedData.state = 4; //생산완료 처리
        }
        copiedData.good_quantity = goodQuantity; //생산량

        const result = await Call_UpdateWork(copiedData.idx, copiedData);

        if (result.affectedRows > 0) {
            setGoodQuantity();//초기화
            setIsModalOpen2(false);
            return true;
        } else {
            alert('변경 실패...')
            return false;
        }
    };
    const handleCancel2 = () => {
        setGoodQuantity();//초기화
        setIsModalOpen2(false);
    };


    useEffect(() => {
        if (inputQunatity && inputQunatity.current && isModalOpen2) {
            console.log("Modal Open!")
            //바로 Focusing 하면 적용되지 않으므로 약간의 지연(1ms)을 두고 포커싱
            setTimeout(() => {
                inputQunatity.current.focus();
            }, 1)
        }

        if (!isModalOpen2) {
            setChecked(true);
            form.resetFields();
        }

    }, [isModalOpen2])

    //Column (생산 테이블 Column)
    const columns = [
        {
            title: 'index',
            dataIndex: 'idx',
            key: 'idx',
            hidden: true
        },
        {
            title: '품목',
            dataIndex: 'product_name',
            key: 'product_name',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        console.log(record, rowIndex);
                    },
                };
            },
            width: "15%",
            sorter: (a, b) => Common.tableSorter(a.product_name, b.product_name, 'string')
        },
        {
            title: '규격',
            dataIndex: 'product_standard',
            key: 'product_standard',
            width: "15%",
            sorter: (a, b) => Common.tableSorter(a.product_standard, b.product_standard, 'string')
        },
        {
            title: '지시량',
            dataIndex: 'order_quantity',
            key: 'order_quantity',
            width: "10%",
            sorter: (a, b) => Common.tableSorter(a.order_quantity, b.order_quantity, 'number')
        },
        {
            title: '생산량',
            dataIndex: 'good_quantity',
            key: 'good_quantity',
            render: (text, record) => <Button className="button" style={{ width: '100%', height: '50px' }} onClick={() => showModal2(record)}>{Common.StateIcon(text).icon}{text}</Button>,
            width: "10%",
            sorter: (a, b) => Common.tableSorter(a.good_quantity, b.good_quantity, 'number')
        },
        {
            title: '단위',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: "5%",
        },
        {
            title: '현재상태',
            dataIndex: 'state_',
            key: 'state_',
            render: (text, record) => <Button className={Common.StateIcon(text).colorClass} onClick={() => showModal(record)} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: '50px' }}>{Common.StateIcon(text).icon}{text}</Button>,
            width: "10%",
            sorter: (a, b) => Common.tableSorter(a.state, b.state, 'number')
        },
        {
            title: '등록일',
            dataIndex: 'order_date_',
            key: 'order_date_',
            width: "10%",
            sorter: (a, b) => Common.tableSorter(a.order_date, b.order_date, 'date')
        },
        {
            title: '비고',
            dataIndex: 'description',
            key: 'description',
            width: "30%",
            sorter: (a, b) => Common.tableSorter(a.description, b.description, 'string')
        },

    ].filter(item => !item.hidden);

    //Modal Column (상태변경 모달 테이블 Column)
    const modalColumn = [
        {
            title: 'RowHead',
            dataIndex: 'RowHead',
            rowScope: 'row',
            width: '20%',
        },
        {
            title: 'Content',
            dataIndex: 'Content',
            // rowScope: 'row',
        }
    ]

    //Modal Row (상태변경 모달 테이블 Row)
    const modalRow = [
        {
            RowHead: "작업번호",
            Content: modaldata.work_no,
        },
        {
            RowHead: "품목",
            Content: modaldata.product_name,
        },
        {
            RowHead: "규격",
            Content: modaldata.product_standard,
        },
        {
            RowHead: "단위",
            Content: modaldata.unit_name,
        },
        {
            RowHead: "현재 상태",
            Content:
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button className={Common.StateIcon(modaldata.state_).colorClass} style={{ marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>{Common.StateIcon(modaldata.state_).icon}{modaldata.state_}</Button>
                </div>,
        },
        {
            RowHead: "상태 변경",
            Content:
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button key={0} className={Common.StateIcon('대기중').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(0)}><HourglassOutlined style={{ verticalAlign: '2px' }} />대기중</Button>
                    <Button key={2} className={Common.StateIcon('생산중').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(2)}><CaretRightOutlined style={{ verticalAlign: '2px' }} />생산중</Button>
                    <Button key={4} className={Common.StateIcon('생산완료').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(4)}><CheckOutlined style={{ verticalAlign: '2px' }} />생산완료</Button>
                    <Button key={1} className={Common.StateIcon('일시정지').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(1)}><PauseOutlined style={{ verticalAlign: '2px' }} />일시정지</Button>
                    <Button key={5} className={Common.StateIcon('긴급').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(5)}><ExclamationCircleOutlined style={{ verticalAlign: '2px' }} />긴급</Button>
                    <Button key={3} className={Common.StateIcon('외주').modalColorClass} style={{ height: "5vh", marginBottom: 5, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => confirmState(3)}><ExportOutlined style={{ verticalAlign: '2px' }} />외주</Button>
                    {contextHolder}
                </div>,
        },
    ];

    //수량 Handler
    const quantityHandler = (e) => {
        const val = e.target.value;
        setGoodQuantity(val)
        keyboard.current.setInput(val)
    };

    //체크버튼 Handler 
    const checkHandler = (e) => {
        const checkState = e.target.checked;
        setChecked(checkState)
    }

    //KeyDown Handler 
    const keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            handleOk2(goodQuantity, checked);
        }
    }

    //Keyboard onChange Handler - 생산량 수정
    const keyboardOnchange = (input) => {
        setGoodQuantity(input);
    }

    //Keyboard onKeyPress Handler - 생산량 수정
    const keyboardOnKeyPress = (button) => {
        console.log(button)
    }

    //Keyboard onChange Handler - 검색어 수정
    const keyboardOnchange2 = (input) => {
        const keyAssy = hangul.assemble(input)
        setSearchVal(keyAssy)
    }

    //Keyboard onKeyPress Handler - 검색어 수정
    const keyboardOnKeyPress2 = (button) => {
        console.log(button)
        if (
            button === `{numbers}` ||
            button === `{abc}` ||
            button === `{shift}` ||
            button === `{symbols}` ||
            button === `{language}` ||
            button === `{languageShift}` ||
            button === `{clear}`
        ) {
            handleShift(button);
        }
    }

    const handleShift = (button) => {
        switch (button) {
            case "{shift}":
                setLayoutName(layoutName === "default" ? "shift" : "default");
                break;
            case "{numbers}":
                setLayoutName("numbers");
                break;
            case "{symbols}":
                setLayoutName("symbols");
                break;
            case "{abc}":
                setLayoutName("default");
                break;
            case "{language}":
                setLayoutName("language");
                break;
            case "{languageShift}":
                setLayoutName(layoutName === "language" ? "languageShift" : "language");
                break;
            case "{clear}":
                setSearchVal("");
                keyboard_search.current.clearInput();
            default:
                break;
        }
    };

    async function Call_UpdateWork(idx, contents) {
        const result = await WorkService.UpdateWork(r_token, r_connStr, idx, contents);
        if (result.affectedRows > 0) {
            setTrigger(true)
            return result;
        } else {
            return null;
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Header />

            <Content
                className="site-layout"
                style={{
                    padding: '20px',
                    height: '93vh',
                    background: "#ffffff",
                    width: Common.ContainerConvert(r_userInfo.system_container_size),
                }}
            >
                <div style={{ height: "5vh", display: "flex", justifyContent: "center", paddingBottom: "10px" }}>
                    <h2>생산</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Search
                        onChange={e => setSearchVal(e.target.value)}
                        placeholder="검색"
                        enterButton
                        style={{ position: "sticky", top: "0", left: "0", paddingBottom: '15px', width: '30vw' }}
                        onFocus={() => { setKeyboardVisibility(true) }}
                        value={searchVal}
                    />
                </div>
                <Table
                    rowKey="idx"
                    columns={columns}
                    dataSource={filteredData}
                    loading={loading}
                    locale={{
                        triggerDesc: '내림차순 정렬',
                        triggerAsc: '오름차순 정렬',
                        cancelSort: '정렬 취소',
                    }}
                    pagination={false}
                />
                <Modal
                    centered={true}
                    width={'70vw'}
                    title="작업 상태 변경"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={'확인'}
                    cancelText={'닫기'}
                    okButtonProps={{ className: "button", style: { display: 'none' } }}
                    cancelButtonProps={{ className: 'button' }}
                    closeIcon={false}
                >
                    <Table rowKey="RowHead" columns={modalColumn} dataSource={modalRow} showHeader={false} bordered={true} pagination={false} />
                </Modal>

                <Modal
                    forceRender={true} //useForm을 위해 미리 렌더링
                    centered={true}
                    width={'55vw'}
                    title="생산량 입력"
                    open={isModalOpen2}
                    onOk={() => handleOk2(goodQuantity, checked)}
                    onCancel={handleCancel2}
                    okText={'확인'}
                    cancelText={'닫기'}
                    okButtonProps={{ className: "button" }}
                    cancelButtonProps={{ className: 'button' }}
                    closeIcon={false}
                >

                    <Form form={form}>
                        <Form.Item
                            name="formQuantity"
                            rules={[
                                {
                                    required: true,
                                    message: '생산량을 입력해주세요!',
                                },
                            ]}
                        >
                            <div style={{ marginTop: 30 }}>
                                <Input ref={inputQunatity} type="number" placeholder="생산량을 입력해주세요" onChange={quantityHandler} onKeyDown={keyDownHandler} value={goodQuantity} />
                                <Keyboard
                                    keyboardRef={(r) => (keyboard.current = r)}
                                    onChange={keyboardOnchange}
                                    onKeyPress={keyboardOnKeyPress}
                                    layoutName={'simpleNumbers'}
                                    layout={Common.customLayout}
                                    display={Common.customDisplay}
                                />

                            </div>
                        </Form.Item>

                        <Form.Item>
                            <div style={{ marginTop: 30 }}>
                                <Checkbox onChange={checkHandler} checked={checked}>생산완료 처리</Checkbox>
                            </div>
                        </Form.Item>

                    </Form>

                </Modal>
            </Content>
            {
                keyboardVisibility &&
                // <div style={{ position: 'absolute ', bottom: '0', width:Common.ContainerConvert(r_userInfo.system_container_size), padding:'20px',}}>
                <div style={{ position: 'absolute ', bottom: '0', width: '60%', padding: '20px', }}>
                    <Keyboard
                        keyboardRef={(r) => (keyboard_search.current = r)}
                        onChange={keyboardOnchange2}
                        onKeyPress={keyboardOnKeyPress2}
                        layoutName={layoutName}
                        layout={Common.customLayout}
                        display={Common.customDisplay}

                    />
                </div>
            }
            {
                r_calc &&
                <div style={{ position: 'absolute ', right: '0', top: '10vh', width: '30%', padding: '20px', opacity: '0.7' }}>
                    <Calculator />
                </div>
            }
        </div>
    )
}
export default Manufacture;