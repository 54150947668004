import API from '../API';

function GetAllStock(accessToken, connStr){
    const config = {
        data: {},
        headers:{
          'access-token': accessToken,
          'connection-string' : connStr,
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }
    return API.get('api/stock/stocks', config).then((response)=>{ 
        const result = response.data.result;
        return result
    })
    .catch(function(err){
        console.log(err);
    })
}

export default {GetAllStock}