import API from '../API';
import axios from 'axios';

function GetAttendanceDate(accessToken, connStr, date) {
    let config = {
        data: {},
        headers: {
            'access-token': accessToken,
            'connection-string': connStr,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true
    }

    return API.get(`api/attendance/${date}`, config).then((response) => {
        const result = response.data.result;
        return result;
    }).catch(function (error) {
        console.log(error);
    });
}

function SetAttendance(accessToken, connStr, date, code) {
    const data = {
        date: date,
        code: code
    }

    const config = {
        headers: {
            'access-token': accessToken,
            'connection-string': connStr,
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: true // Cookie 함께 전송하는 옵션
    }

    return axios.post('api/attendance', data, config).then((response) => {
        const result = response.data;
        return result;
    }).catch(function (error) {
        console.log(error);
    })
}

const AttendanceServices = {
    GetAttendanceDate,
    SetAttendance,
}

export default AttendanceServices;